import { useEffect, useState, useContext } from "react"
import { useTranslation } from "react-i18next"
import { GlobalContext } from "context/context/GlobalContext"
import { useHistory, useParams } from "react-router-dom"
import Loading from "overrides/theme/entel/base/components/Commons/Loading/Loading"
import SearchForm from "overrides/theme/entel/base/components/SearchForm/SearchForm"
import { useOSRM } from "overrides/theme/entel/base/hooks"
import { OrderDetailStyled } from "overrides/theme/entel/base/pages/DetailOrderScreens/styles/OrderDetail.style"
import OrderDetails from "overrides/theme/entel/base/pages/DetailOrderScreens/OrderDetails"
import { useEnv } from "hooks" 

const OrderDetailScreen = () => {
  const { t } = useTranslation()
  const { permissions } = useEnv()
  const { displayHeaderRouteName } = useContext(GlobalContext)
  const { shippingtype, type, shippingGroupId, id }: any = useParams()
  const history = useHistory()
  const [loading, set_loading] = useState(false)
  const [data, set_data]: any = useState({})

  const { get_sg_by_id, get_order_by_id, get_items_by_sku } = useOSRM()

  const handleClickSearch = (e: any) => history.push(`/search/${e.optionFilter}/${e.query}`)

  useEffect(() => {
    let shippingTypeTitle = data?._order?.shippingType
    displayHeaderRouteName.dispatch(shippingTypeTitle ? `Orden ${t(shippingTypeTitle)}` : "Detalle de Órden")
    //eslint-disable-next-line
  }, [data])

  useEffect(() => {
    const post_request = async () => {
      let res: any = null
      let res_grouped: any = {}
      set_loading(true)

      res = await get_sg_by_id(
        type,
        shippingGroupId,
        id,
        ["rt", "hdr"].includes(shippingtype) ? "target.source.id" : "source.id"
      )
      res_grouped = { ...res_grouped, _sg: { ...res.data.message.records[0] } }

      res = await get_order_by_id(id)
      res_grouped = { ...res_grouped, _order: { ...res.data.message.records[0] } }

      const skus = res_grouped._sg?.source?.items.map((item: any) => item.sku)
      res = await get_items_by_sku(skus || [])

      const product_list = res_grouped._sg?.source?.items.map((item: any) => {
        let _item: any = item
        res.data.message.records.forEach((element: any) => {
          if (_item.sku === element.sku) {
            _item = { ..._item, ...element }
          }
        })
        return _item
      })

      res_grouped = { ...res_grouped, _items: product_list }
      set_data(res_grouped)
      set_loading(false)
    }

    post_request()
    //eslint-disable-next-line
  }, [])

  const template = (
    <>
      {loading ? (
        <Loading defaultOpened={loading} />
      ) : (
        <>
          {permissions.get_permission("global", "block-search-details") && <SearchForm onClickSearch={handleClickSearch} backButton={true} />}
          {data.hasOwnProperty("_sg") && <OrderDetails data={data} set_loading={set_loading} />}
          <style jsx>{OrderDetailStyled}</style>
        </>
      )}
    </>
  )

  return template
}

export default OrderDetailScreen
